/**
 * This file is used as the 404 page in your app. If users go to a URL that does
 * not exist within your app, they will be shown this page.
 *
 * This page is also used to support Prismic preview session when viewing
 * documents that have not been published.
 *
 * @see https://www.gatsbyjs.com/docs/how-to/adding-common-features/add-404-page/
 * @see https://prismic.io/docs/technical-reference/gatsby-plugin-prismic-previews#withprismicunpublishedpreview
 */

import { Link } from "gatsby"
import { withPrismicUnpublishedPreview } from "gatsby-plugin-prismic-previews"
import { Text } from "../components/Text"
import { SEO } from "../components/SEO"
import { useSiteSettings } from "../hooks/useSiteSettings"

import notFoundSvgUrl from "../assets/not-found-bg.svg"

const NotFoundPage = () => {
	return (
		<>
			<div className="bg-black px-[46px] py-[100px] relative overflow-hidden">
				<div className="absolute inset-0 overflow-hidden lg:-inset-1">
					<img
						src={notFoundSvgUrl}
						alt=""
						className="w-[1238px] h-[774px] object-cover object-center lg:w-full lg:h-full"
						width={1706}
						height={1067}
						loading="eager"
					/>
				</div>

				<div className="bg-white px-[17px] py-[64px] text-center isolate flex flex-col items-center max-w-[820px] mx-auto xl:pt-[100px]">
					<Text variant="notFoundHeading" className="mb-5 text-red">
						404
					</Text>
					<Text variant="heading3" className="mb-5">
						Page Not Found
					</Text>
					<Text variant="heading4" className="mb-5 max-w-[477px] mx-auto">
						The page you are looking for does not exist or an other error has
						occured.
					</Text>

					<Link to="/" className="block pb-2 uppercase border-b-2 border-red">
						<Text variant="heading4" className="mt-6">
							Back To Home
						</Text>
					</Link>
				</div>
			</div>
		</>
	)
}

export const Head = () => {
	const settings = useSiteSettings()

	return (
		<SEO
			siteName={settings.siteName}
			siteDescription={settings.siteDescription}
			pageTitle="Not Found"
			twitter={settings.twitter}
			openGraph={settings.openGraph}
		/>
	)
}

/**
 * When a Prismic preview session is active and an editor lands on the 404 page,
 * it means the app does not contain a page for the previewed document's URL
 * determined using your app's Link Resolver.
 *
 * `withPrismicUnpublishedPreview` will detect when a preview session is active
 * and try to display the previewed document using the configuration provided.
 *
 * @see https://prismic.io/docs/technical-reference/gatsby-plugin-prismic-previews#withprismicunpublishedpreview
 */
export default withPrismicUnpublishedPreview(NotFoundPage)
